import { useState, useEffect } from "react";
import logo from './logo.svg'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import Drawer from '@mui/material/Drawer';
import Menu from '@mui/material/Menu';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import Slider from '@mui/material/Slider';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function Landing() {

    const [state, setState] = useState({
        right: false,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [openNoti, setOpenNoti] = useState(false);
    const handleCloseNoti = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenNoti(false);
    };

    function randomString(length, uppercase = true, lowercase = true, numbers = true, symbols = true) {
        var uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
        var numberChars = "0123456789";
        var symbolChars = "!@#$%^&*()_/?";
        var allChars = uppercaseChars + lowercaseChars + numberChars + symbolChars;
        var typesCount = (uppercase ? 1 : 0) + (lowercase ? 1 : 0) + (numbers ? 1 : 0) + (symbols ? 1 : 0);
        var lengthForEach = length / typesCount;
        var result = "";
        if (uppercase) {
            for (var i1 = 0; i1 < lengthForEach; i1++) {
                result += uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)];
            }
        }
        if (lowercase) {
            for (var i2 = 0; i2 < lengthForEach; i2++) {
                result += lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)];
            }
        }
        if (numbers) {
            for (var i3 = 0; i3 < lengthForEach; i3++) {
                result += numberChars[Math.floor(Math.random() * numberChars.length)];
            }
        }
        if (symbols) {
            for (var i4 = 0; i4 < lengthForEach; i4++) {
                result += symbolChars[Math.floor(Math.random() * symbolChars.length)];
            }
        }
        if (result.length < length) {
            var left = length - result.length;
            for (var i5 = 0; i5 < left; i5++) {
                result += allChars[Math.floor(Math.random() * allChars.length)];
            }
        }
        result = result.split('').sort(function () { return 0.5 - Math.random() }).join('');
        return result;
    }

    const [uppercase, setUppercase] = useState(true);
    const [lowercase, setLowercase] = useState(true);
    const [numbers, setNumbers] = useState(true);
    const [symbols, setSymbols] = useState(true);
    const [passwordLength, setPasswordLength] = useState(15);
    const [passwordInput, setPasswordInput] = useState(randomString(passwordLength));

    const genPassword = () => {
        setPasswordInput(randomString(passwordLength, uppercase, lowercase, numbers, symbols));
    }

    const copyPassword = () => {
        navigator.clipboard.writeText(passwordInput)
        setOpenNoti(true);
    }

    useEffect(() => {
        setPasswordInput(randomString(passwordLength, uppercase, lowercase, numbers, symbols));
    }, [passwordLength, uppercase, lowercase, numbers, symbols]);

    return (
        <div id="main">
            <div className="flex justify-between items-center p-4" style={{ boxShadow: '0 0 120px 0 rgb(0 0 0 / 15%)' }}>
                <img src={logo} className="w-24" alt="logo" />
                <div className="flex gap-4">
                    <button onClick={toggleDrawer('right', true)}>
                        <MenuOutlinedIcon />
                    </button>
                    <button onClick={handleClick}>
                        <GridViewOutlinedIcon />
                    </button>

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <div className="grid grid-cols-2 gap-4 p-4">
                            <div className="text-center">
                                <a href="https://tax-calculator.8content.com/">
                                    <div className="w-16 h-16 rounded-full ring-gray-300 bg-indigo-800 flex justify-center items-center mx-auto">
                                        <PercentOutlinedIcon sx={{ color: '#ffffff', fontSize: 30 }} />
                                    </div>
                                    <p className="leading-5 my-4">คำนวณภาษี<br/>ออนไลน์</p>
                                </a>
                            </div>
                            <div className="text-center">
                                <a href="https://qrcode-generator.8content.com/">
                                    <div className="w-16 h-16 rounded-full ring-gray-300 bg-indigo-800 flex justify-center items-center mx-auto">
                                        <QrCode2OutlinedIcon sx={{ color: '#ffffff', fontSize: 30 }} />
                                    </div>
                                    <p className="leading-5 my-4">สร้าง QR Code <br/>ออนไลน์</p>
                                </a>
                            </div>
                        </div>
                    </Menu>
                </div>
            </div>
            <div className="mx-auto p-8" style={{ maxWidth: '750px' }}>
                <div className="md:flex gap-4 items-center" style={{ minHeight: 'calc(100vh - 150px)' }}>
                    <div>
                        <h1 className="text-3xl font-extrabold mb-2 text-center">สร้างรหัสผ่านปลอดภัย สุ่มรหัสผ่านออนไลน์</h1>
                        <p className="mb-10 text-center">สร้างรหัสผ่านแบบปลอดภัย รหัสผ่านแบบยาก สุ่มรหัสผ่าน สุ่มรหัสผ่านออนไลน์</p>
                        <div className="bg-white py-4 px-8 shadow-2xl rounded-lg flex justify-between items-center mb-10 flex-wrap">
                            <input
                                type="text"
                                className="border-0 focus:ring-0 outline-0 bg-transparent md:text-4xl text-3xl max-w-full md:mb-0 mb-5"
                                value={passwordInput}
                                onChange={(e) => setPasswordInput(e.target.value)}
                            />
                            <div className="flex gap-3 md:w-auto w-full md:justify-end justify-center">
                                <button
                                    className="md:w-14 md:h-14 md:p-0 px-5 py-2 rounded-full bg-indigo-800 text-white"
                                    onClick={copyPassword}
                                >
                                    <ContentCopyOutlinedIcon />
                                    <span className="md:hidden inline-flex pl-2">คัดลอก</span>
                                </button>
                                <button
                                    className="md:w-14 md:h-14 md:p-0 px-5 py-2 rounded-full bg-indigo-800 text-white"
                                    onClick={genPassword}
                                >
                                    <CachedOutlinedIcon />
                                    <span className="md:hidden inline-flex pl-2">สร้างใหม่</span>
                                </button>
                            </div>
                        </div>
                        <div className="bg-white py-6 px-8 shadow-2xl rounded-lg  mb-8">
                            <div className="flex justify-between items-center">
                                <h4>ความยาวของรหัสผ่าน</h4>
                                <h4>{passwordLength} ตัวอักษร</h4>
                            </div>

                            <div className="my-3">
                                <Slider
                                    value={passwordLength}
                                    onChange={(e) => setPasswordLength(e.target.value)}
                                    min={8}
                                    max={30}
                                    aria-label="Default"
                                    valueLabelDisplay="auto"
                                />
                            </div>
                            <div className="grid md:grid-cols-4 grid-cols-2 md:gap-4 gap-2">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={uppercase}
                                            onChange={(e) => {
                                                document.querySelectorAll(`[type="checkbox"]:checked`).length > 0 ?
                                                    setUppercase(e.target.checked) :
                                                    setUppercase(true)
                                            }}
                                        />
                                    }
                                    label="Uppercase"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={lowercase}
                                            onChange={(e) => {
                                                document.querySelectorAll(`[type="checkbox"]:checked`).length > 0 ?
                                                    setLowercase(e.target.checked) :
                                                    setLowercase(true)
                                            }}
                                        />
                                    }
                                    label="Lowercase"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={numbers}
                                            onChange={(e) => {
                                                document.querySelectorAll(`[type="checkbox"]:checked`).length > 0 ?
                                                    setNumbers(e.target.checked) :
                                                    setNumbers(true)
                                            }}
                                        />
                                    }
                                    label="Numbers"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={symbols}
                                            onChange={(e) => {
                                                document.querySelectorAll(`[type="checkbox"]:checked`).length > 0 ?
                                                    setSymbols(e.target.checked) :
                                                    setSymbols(true)
                                            }}
                                        />
                                    }
                                    label="Symbols"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Drawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
            >
                <div className="p-8" style={{ minWidth: '250px' }}>
                    <ul className="text-xl space-y-4">
                        <li><a href="https://8content.com/">หน้าหลัก</a></li>
                        <li><a href="https://8content.com/%e0%b8%a3%e0%b8%b1%e0%b8%9a%e0%b8%97%e0%b8%b3%e0%b9%80%e0%b8%a7%e0%b9%87%e0%b8%9a%e0%b9%84%e0%b8%8b%e0%b8%95%e0%b9%8c-2/">เกี่ยวกับเรา</a></li>
                        <li><a href="https://8content.com/services/">บริการของเรา</a></li>
                        <li><a href="https://8content.com/blog/">บทความ</a></li>
                        <li><a href="https://8content.com/%e0%b8%95%e0%b8%b4%e0%b8%94%e0%b8%95%e0%b9%88%e0%b8%ad%e0%b9%80%e0%b8%a3%e0%b8%b2/">ติดต่อเรา</a></li>
                    </ul>
                </div>
            </Drawer>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={openNoti}
                autoHideDuration={6000}
                onClose={handleCloseNoti}
            >
                <Alert severity="success" color="primary" onClick={() => setOpenNoti(false)}>
                    <p className="pr-4">คัดลอกสำเร็จ</p>
                </Alert>
            </Snackbar>
        </div>
    );
}
